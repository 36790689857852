<template>
  <v-card class="py-6 px-4" v-if="vacancy">
    <v-row class="d-flex flex-row justify-space-between align-center px-0 my-3">
      <div class="mx-0">
        <v-card-title class="text-h5" v-text="vacancy.title"></v-card-title>
      </div>
    </v-row>
    <v-row class="d-flex flex-row justify-space-between align-center px-3 my-3">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div class="text--secondary body-2" v-if="vacancy.locale">
          <v-btn x-small color="primary" dark class="mx-0">
            {{ vacancy.type }}
          </v-btn>
          <v-btn x-small color="secondary" dark class="mx-2">
            {{ vacancy.category }}
          </v-btn>
        </div>
      </div>
    </v-row>
    <v-divider></v-divider>
    <div class="py-4" v-if="vacancy.entity">
      <v-row class="d-flex flex-row align-center">
        <!-- <pre>{{vacancy}}</pre> -->
        <v-list-item>
          <v-list-item-avatar color="grey">
            <img
              :src="
                vacancy.entity.logo
                  ? `${apiUrl}/images/entity/${vacancy.entity.logo}`
                  : `/avatar-whire.png`
              "
              alt=""
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="black--text" v-if="vacancy.entity">
              {{ vacancy.entity.name ? vacancy.entity.name : "Instituicao" }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-html="
                formatDate(vacancy.finalDate) === 'Expirado ' ||  formatDate(vacancy.finalDate) === 'Expired '
                  ? `<span class=\'subtitle\' style=\'color:red\'>${this.$t('expired')}</span>`
                  : formatDate(vacancy.finalDate)
              "
            >
            </v-list-item-subtitle>
            
          </v-list-item-content>
          <v-list-item-action>
            <v-row>
              <v-btn :href="`//${vacancy.entity.media.facebook}`" target="_blank" icon>
                <v-icon small color="primary">mdi-facebook</v-icon>
              </v-btn>
              <v-btn :href="`//${vacancy.entity.media.linkedin}`" target="_blank" icon>
                <v-icon small color="primary">mdi-linkedin</v-icon>
              </v-btn>
              <v-btn :href="`//${vacancy.entity.media.twitter}`" target="_blank" icon>
                <v-icon small color="primary">mdi-twitter</v-icon>
              </v-btn>
            </v-row>
          </v-list-item-action>
        </v-list-item>
        <v-row class="d-flex flex-row align-center">
          <p
            class="mx-7 mt-4 text-justify subtitle-2 display-4"
            style="color:rgba(0,0,0,.6)"
          >
            {{ vacancy.entity.about }}
          </p>
        </v-row>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="my-8 px-3">
      <v-row class="d-flex flex-row align-center">
        <div><v-icon left small>place</v-icon></div>
        <div
          class="text-caption mr-1"
          v-for="place in vacancy.place"
          :key="place"
        >
          {{ place }},
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            :disabled="status2(vacancy).status === 'Expirado' ||  status2(vacancy).status  === 'Expired'  || appliedFor"
            tile
            color="primary"
            class="body-2 text-uppercase px-2"
            @click.stop="openDialog()"
            >{{ vacancy.locale ==='pt'?'Candidatar-se':  'Apply'}} </v-btn
          >
          
        </div>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="pt-8 mb-6 black--text">
      <div
        class="text--secondary body-2 black--text"
        v-html="vacancy.description"
      ></div>
    </div>

    <!-- <v-list-item
      class="d-flex flex-row justify-space-between align-center px-0 my-3"
    >
      <v-list-item-content>
        <v-list-item-title class="text-left text-h6  black--text"
          >Requisitos obrigatórios</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item> -->
<!-- <pre>{{getPublishingLang(vacancy.locale).yearExperiencie}}</pre> -->
    <v-list-item
      two-line
      class="d-flex flex-row justify-space-between align-center px-0 my-3"
    >
      <v-list-item-content>
        <v-list-item-title class=" black--text mb-3">
          {{
           getPublishingLang(vacancy.locale).academicEducation
          }}</v-list-item-title
        >
        <v-list-item-subtitle
          class="subtitle  mb-2"
          v-for="formation in vacancy.formation"
          :key="formation.course"
        >
          {{ getCourse(formation.course) }} -
          {{ formation.level }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      two-line
      class="d-flex flex-row justify-space-between align-center px-0 my-2"
    >
      <v-list-item-content>
        <v-list-item-title class=" black--text mb-3">
          {{  getPublishingLang(vacancy.locale).languages }}</v-list-item-title
        >
        <v-list-item-subtitle
          class="subtitle  mb-2"
          v-for="language in vacancy.languages"
          :key="language.name"
        >
          {{ language.name }} - {{ language.level }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      two-line
      class="d-flex flex-row justify-space-between align-center px-0 my-2"
    >
      <v-list-item-content>
        <v-list-item-title class=" black--text mb-3">
          {{
           getPublishingLang(vacancy.locale).yearExperiencie
          }}</v-list-item-title
        >
        <v-list-item-subtitle
          class="subtitle  "
          v-for="(professionalExp, index) in vacancy.professionalExperience"
          :key="index"
        >
          {{ professionalExp.yearsExperience }}
          {{
            professionalExp.yearsExperience > 1
              ? getPublishingLang(vacancy.locale).years
              : getPublishingLang(vacancy.locale).year
          }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <apply-vacancy />
  </v-card>
</template>

<script>
import { API_URL } from "@/api";
import { GET_VACANCY_BY_ID_QUERY } from "../graphql/Query.resolver";
import { mapGetters } from "vuex";
import { statusVacancyMixins } from "@/mixins/status";
import ApplyVacancy from "./ApplyVacancy.vue";
import moment from "moment";
export default {
  components: { ApplyVacancy },
  name: "DetailsCard",
  props: {
    guest: Boolean,
  },
  mixins: [statusVacancyMixins],
  data: () => ({
    apiUrl: API_URL,
    vacancy: {},
  }),
  apollo: {
    vacancy: {
      query: GET_VACANCY_BY_ID_QUERY,
      variables() {
        return { id: this.$route.params.id };
      },
      fetchPolicy: "no-cache",
    },
  },
  computed: {
    ...mapGetters({
      getPublishingLang: "library/publishingLang",
      getTrainingAreas: "library/trainingAreas",
    }),
  },
  created(){
    this.loginUrl = '/candidate'+window.location.pathname
    localStorage.removeItem("loginParams")
    localStorage.setItem("loginUrl", this.loginUrl);
  },
  methods: {
    openDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("openGuestApplyModal", this.vacancy);
    },
    formatDate(date) {
      if (date) {
        const d1 = new Date(date);
        const d2 = new Date();
        if (d1 < d2) {
          return this.expired(this.vacancy.locale) //+this.vacancy.locale
        } else {
          moment.locale(this.vacancy.locale);
         return this.expiredAt(this.vacancy.locale) + moment(date).format("LL");
        }
      }
      return "";
    },
    getCourse(course) {
      const areas = this.getTrainingAreas(this.vacancy.locale);
      const index = areas.findIndex((a) => a.key == course);
      return areas[index] ? areas[index].text : '';
    },
    expiredAt(locale){
      if (locale === 'pt') {
        return 'Expira a '
      }else{
        return 'Expire at '
      }
    },
    expired(locale){
      if (locale === 'pt') {
        return 'Expirado '
      }else{
        return 'Expired '
      }
    }
  },
};
</script>
